import React from "react";
import Layout from "../../components/layout";
import member from "../../images/member.jpg";

const IndexPage = () => {
  return (
    <Layout>
      <hr />
      <div
        style={{
          color: "black",
          paddingLeft: "4rem",
          paddingRight: "4rem",
          textAlign: "center",
          marginTop: "4rem",
        }}
      >
        <div class="py-16">
          <div class="container m-auto px-6">
            <div class="lg:flex justify-between items-center">
              <div class="lg:w-6/12 lg:p-0 p-7">
                <h1 class="text-4xl font-bold leading-tight mb-5 ">
                  {" "}
                  Helping you sell subscriptions
                </h1>
                <p class="text-xl">
                  {" "}
                  From One-time webinars To lifetime subscriptions inflowkit
                  reduces the time and cost of building up, growing and
                  converting a list of loyal subscribers or customers. We offer
                  everything required to create, promote, host, sell and track
                  digital products in one place. If you are struggling to grow a
                  business or increase revenue we will help you  drive more
                  sales at higher prices through your content.
                </p>

                <div class="py-5">
                  <a
                    href="#"
                    class="text-white rounded-full py-2 px-5 text-lg font-semibold bg-purple-600 inline-block border border-purple-600 mr-3"
                  >
                    Try for free
                  </a>
                </div>
              </div>
              <div class="lg:w-5/12 order-2">
                <img src={member}
                  alt={"Sell downloads"}
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(191deg) rotateX(2deg) rotate(2deg)",
                  }}></img>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
